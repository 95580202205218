<template>
    <dialog ref="modal" class="modal">
        <img
            class="modal__closeBtn"
            src="@/assets/icons/close-button.svg"
            @click.stop="closeModal" />

        <h3 class="modal__title">{{ title }}</h3>

        <form @submit.prevent="updateTag" class="modal__form">
            <input
                type="text"
                v-model="name"
                :placeholder="placeholder"
                required
                />

            <div class="modal__form__container">
                <span class="modal__form__container--error">{{ error }}</span>
            </div>
            <input ref="submitBtn" type="submit" value="Update tag name" />
        </form>
    </dialog>
</template>

<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
    name: "TagModal",
    props: {
        costumData: Object,
        title: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Input value',
        }
    },
    data() {
        return {
            name: '',
            error: ''
        }
    },
    mounted() {
        this.$refs.modal.showModal();
        this.$refs.modal.addEventListener('close', () => {
            this.closeModal();
        })
        this.name = this.costumData.name;
    },
    methods: {
        refreshTags(data) {
            this.$emit("refresh", data);
        },
        closeModal() {
            this.$emit("closeModal");
        },
        async updateTag() {
            let trimedName = this.name.trim();
            if(trimedName.length === 0) {
                this.error = 'Tag name is a required field.';
                return; 
            }

            if(trimedName.length < 3 || trimedName.length > 20) {
                this.error = 'Tag name length must be between 3 and 20 characters.';
                return;
            }

            this.error = '';
            this.$refs.submitBtn.disabled = true;
            const response = await this.$api.updateTagName(this.costumData.id, trimedName, this.userStore.accessToken);
            if(!response.success) {
                this.error = response.error.message;
                this.$refs.submitBtn.disabled = false;
                return;
            }

            const { tag } = response;
            this.refreshTags(tag);
            this.closeModal();
        }
    },
    computed: {
		...mapStores(useUserStore),
	}
}

</script>

<style lang="scss" scoped>
    .modal {
        @include box-shadow;
        @include side-component;

        max-width: 50ch;
        background-color: var(--dark);
        border: none;
        text-align: center;

        &::backdrop {
            background: rgb(0 0 0 / .6);
        }

        & > * {
            margin: 0 0 .5rem 0;
        }

        &__closeBtn {
            @extend .pointer;
            @extend .noselect;
            @extend .nodrag;
            position: absolute;
            top: 10px;
            left: 10px;

            &:hover {
            @include popup();
            }
        }

        &__title {
            @include side-component-title;
            margin: 20px 0px 30px 0px;
        }

        &__form {
            & > * {box-sizing: border-box;}

            input {
                @include text-input;

                width: 100%;
                padding: 7px;
                margin: 0 auto;
                margin-bottom: 10px;
                border: none;

                display: block;
            }
            input[type="submit"] {
                @extend .pointer;

                width: 60%;
                border: none;
                background-color: var(--orange);

                &:hover {
                    @include popup(1.02);
                }

                &:disabled {
                    background-color: var(--medium);

                    &:hover {
                        transform: none;
                    }
                }
            }

            &__container {
                margin: 0 auto 10px 0;
                text-align: left;

                &--error {
                    font-size: medium;
                    color: var(--orange);
                    font-weight: bold;
                }
            }
        }
    }

</style>