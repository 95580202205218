<template>
	<div v-if="!deleted" @click="mark"
		 :class="{ marked: marked, unmarked: !marked }"
		 :title="name">
		<h4>{{ name }}</h4>
		<button @click.stop="onOpenModal" class="renameTagBtn" v-if="isAdmin && canDelete">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
				<path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/>
			</svg>
		</button>
		<button @click.stop="deleteTag" class="delete-tag" v-if="isAdmin && canDelete">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
				<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
			</svg>
		</button>
	</div>
</template>

<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
export default {
	name: "TagSticker",
	props: {
		id: Number,
		name: String,
		subscribed: Boolean,
		canDelete: Boolean
	},
	data() {
		return {
			marked: this.subscribed,
			deleted: false,
			isAdmin: false
		};
	},
	created() {
		this.isAdmin = this.userStore.userRole === 'admin';
	},
	methods: {
		mark(e) {
			if(e.target.nodeName.toUpperCase() !== 'BUTTON' && e.target.nodeName.toUpperCase() !== 'SVG') {
				this.marked = !this.marked;
				this.$emit("changed", this.id, this.name);
			}
		},		
		onOpenModal() {
			this.$emit("open", { id: this.id, name: this.name });
		},
		deleteTag() {
			const really = confirm("This action can not be undone. Continue?");
			if (really) {
				this.deleted = true;
				this.marked = false;
				this.$emit("deleted", this.id);
			}
		}
	},
	computed: {
		...mapStores(useUserStore),
	}
}
</script>

<style lang="scss" scoped>
	div {
		@extend .pointer;
		@extend .noselect;

		display: flex;
		align-items: center;
		justify-content: center;

		max-width: 33%;
		max-height: 1em;
		margin: 3px;
		padding: 10px;

		background-color: var(--light);
		border-radius: 10px;

		h4 {
			@include dot-overflow;
			font-size: 0.8em;
			font-weight: normal;
			color: var(--dark);
		}

		.delete-tag {
			@include button(5px, false, var(--medium-dark));
			background: none;
			padding: 3px 4px;
			margin-right: -5px;
			line-height: 1em;
			color: var(--medium-dark);
			&:hover {
				color: var(--medium-dark);
			}
		}
		
		.renameTagBtn {
			@include button(5px, false, var(--medium-dark));
			background: none;
			padding: 3px 4px;
			margin-right: -5px;
			line-height: 1em;
			color: var(--medium-dark);
			&:hover {
				color: var(--medium-dark);
			}
		}
	}
	.marked {
		background-color: var(--orange);
	}
</style>
