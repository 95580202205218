<template>
	<div class="main tags-page">
		<!-- Navbar only renders if the user isn't coming from the registration page -->
		<Navbar v-if="!firstTime" />

		<section :class="{firstTime: firstTime}">
			<h1>Please select TAGs you want to follow on efan.gg</h1>

			<TagSearch
				class="search"
				v-if="ready"
				:tags="tags"
				:n="20"
				:trigger="trigger"
				@updated="updated"
			/>

			<!-- <div class="tagContainer subscribed">
				<h2>Subscribed</h2>

				<div class="tags">
					<h3 v-if="subscribed.length === 0" class="noSubscribedTags">You aren't subscribed to any tags.</h3>
					<TagSticker
						v-for="tag in subscribed"
						:key="tag.id"
						v-bind="tag"
						@changed="updateSubscription"
					/>
				</div>
			</div> -->

			<div class="tagContainer all">
				<h2>All tags</h2>

				<div class="tags">
					<TagSticker
						v-for="tag in filtered"
						:key="tag.id"
            			:can-delete="true"
						v-bind="tag"
						@open="onOpenModal"
						@changed="updateSubscription"
            			@deleted="deleteTag"
					/>

					<div v-if="newTag.formVisible" id="newTagForm">
						<h3>Want to add a new tag?</h3>
						<h4>Please enter the full tag name.</h4>
						<input type="text"
							   v-model="newTag.name"
							   placeholder="Tag"
							   v-on:keyup.enter="submitNewTag"
							   maxlength=50
						>
					</div>
				</div>
			</div>
        	<button type="button"  @click="redirect" class="homeBtn">CONTINUE</button>
		</section>
		<TagModal 
			v-if="showModal"
			ref="modal" 
			:costumData="tag"
			:title="'Edit tag name'"
			:placeholder="'Tag name'"
			@closeModal="onModalClosed"
			@refresh="onRefreshTags" />
	</div>
</template>

<script>
// Store
import { useUserStore } from "@/stores/UserStore";
import { mapStores, mapWritableState } from "pinia";
import { useUserTagStore } from "@/stores/tagsStore.js";

// Components
import Navbar from "@/components/Navbar";
import TagSticker from "@/components/tag/TagSticker";
import TagSearch from "@/components/tag/TagSearch";
import TagModal from "@/components/tag/TagModal";

export default {
	name: "Tags",
	components: {TagSticker, TagModal, TagSearch, Navbar},
	data() {
		return {
			tags: [],		// All tags in database
			filtered: [],	// Tags matching search query
			subscribed: [],	// Tags the user is subbed to
			ready: false,
			trigger: 0,
			firstTime: false,
			showModal: false,
			tag: null,

			newTag: {
				formVisible: false,
				name: "",
				value: ""
			}
		};
	},
	mounted() {
		this.generateTags();
		this.firstTime = this.$route.query.f;
	},
	computed: {
		...mapStores(useUserStore),
		...mapWritableState(useUserTagStore, ['hasTags']),
	},	
	methods: {
		onOpenModal(tag) {
			this.tag = tag;
			this.showModal = true;
		},
		onModalClosed() {
			this.tag = null;
			this.showModal = false;
		},
		async onRefreshTags(data) {
			const indexF = this.filtered.findIndex(el => el.id === data.id);
			const indexSub = this.subscribed.findIndex(el => el.id === data.id);
			if(indexSub > -1) this.subscribed[indexSub].name = data.name;
			if(indexF > -1) this.filtered[indexF].name = data.name;
		},
		async updateSubscription(tagId, name) {
			await this.$api.updateSubscription(this.userStore.user.id, tagId, this.userStore.accessToken);
			this.$store.updateSubscribed(tagId, name);
			this.hasTags = this.$store.getSubscribed();
			this.generateTags();
		},
		deleteTag(tagId) {
			this.$store.removeSubscribed(tagId);
			let index = this.subscribed.findIndex(el => el.id === tagId);
			if (index !== -1) {
				this.subscribed.splice(index, 1);
			}
			this.$api.softDeleteTag(tagId);
			this.trigger++;
		},
		redirect() {
			let redirectPath = this.$store.getLoginRedirect();
			this.$router.push({path: redirectPath ? redirectPath : "/"});
		},
	async generateTags() {
		this.tags.length = 0;	// Clear tags array

		let allTags = (await this.$api.getTags()).tags;
		let subscribedTags = this.$store.getSubscribed();

		if (subscribedTags !== false) {
			this.subscribed = subscribedTags.map(x => {
				return  {
					id: x.id_tag,
					name: x.name,
					subscribed: true
				}
			});
			
			allTags.forEach(el => {
				let isSubscribed = subscribedTags.findIndex(x => x.id_tag === el.id_tag) !== -1;	// Checks if tag exists in subscribedTags
				this.tags.push({id: el.id_tag, name: el.name, subscribed: isSubscribed});
			});
		}

		this.ready = true;
	},
	updated(filtered, query) {
		this.newTag.formVisible = false;
		this.filtered = filtered;

		// V primeru, da obstaja iskan tag se ne prikaže za dodajanje novega tab-a
		if (!this.tags.some(el => el.name.toLowerCase().includes(query.toLowerCase())))  {
			this.newTag.formVisible = true;
			this.newTag.name = query;
		}

		if (filtered.length === 0) {
			this.newTag.formVisible = true;
			this.newTag.name = query;
		}
	},
	async submitNewTag() {
		// Clientside validation
		if (this.newTag.name.length < 2 || this.newTag.name.length > 50) {
			alert("Tag name should be between 2 and 50 characters");
			return;
		}

		// Insert the tag
		let response = await this.$api.insertTag(this.newTag.name, this.userStore.user.id);
		if (response.success === true) {
			this.newTag.formVisible = false;

			this.$store.updateSubscribed(response.tagId, response.name);

			await this.generateTags();
			this.trigger++;
		} else {
			alert("Inserting tag was not successful.\n"+response.msg);
		}
	}
	}
}
</script>

<style lang="scss" scoped>
	.main {
		background-color: var(--dark);
		height: 100vh;

		.firstTime {
			@include gradient;
			margin-top: 0;
			padding-bottom: 0;
			height: 100vh;
		}
		section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 2% auto auto 70% auto 10%;
			gap: 20px 0px;
			grid-template-areas:
				". ."
				"title title"
				"search search"
				"all all"
				"button button"
				". .";

			margin-top: 60px;

			h1 {	// Title
				grid-area: title;
				text-align: center;
				color: var(--text);

				// @include respond-to('small') {
				// 	grid-area: 1;
				// }
			}
			h3 {
				color: var(--text);
			}
			.search {	// TagSearch component
				grid-area: search;
				@include center;
			}
			.tagContainer {
				@include box-shadow($color: rgba(0,0,0,0.2), $size: 3px);
				width: 70%;
				height: 90%;
				margin: 0 auto;
				padding: 10px;
				background-color: var(--text);
				border-radius: 15px;

				// @include respond-to('small') {
				// 	width: 85vw !important;
				// 	margin-left: 20px;
				// }

				h2 {
					color: var(--text-2);
					text-align: center;
				}
				.tags {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: flex-start;
					align-content: flex-start;

					max-height: 80%;

					overflow: auto;
					@include scrollbars($foreground-color: var(--medium), $background-color: var(--light), $radius: 5px);
				}
			}
			.subscribed {
				grid-area: subscribed;

				// @include respond-to('small') {
				// 	grid-area: 2;
				// }

				.noSubscribedTags {
					color: var(--text-2);
				}
			}
			.all {
				grid-area: all;

				// @include respond-to('small') {
				// 	grid-area: 4;
				// }

				#newTagForm {
					padding: 10px;
					margin: 5px;

					background-color: var(--orange);
					border-radius: 15px;

					h3 {
						color: var(--text);
					}
					h4 {
						color: var(--text-2);
					}
					input {
						@include remove-outline;
						padding: 10px;
						border-radius: 10px;
						border: none;
					}
				}
			}

			.homeBtn {
				grid-area: button;
				@include button($padding: 15px, $color: white, $radius: 15px);
				@include box-shadow($color: rgba(0,0,0,0.2));
				@include center;
				color: var(--dark);
				font-weight: bold;
				width: 110px;
			}
		}

		@include respond-to('medium') {
			section {
				margin-top: 0px;
				// grid-template-rows: 1fr auto auto auto auto 1fr;

				.tagContainer {
					width: 90%;
				}
			}
		}
	}
</style>
